import { Injectable, inject } from '@angular/core';
import { AssetState, PeopleGroupState } from './states';
import { Asset, PeopleGroup } from '../domain';
import { CoreStore } from './core.store';
import { AssetApiService, PeopleGroupApiService } from '../services/apis';
import { catchError, EMPTY, finalize, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { QueryOptions } from '../services/types';

@Injectable({
  providedIn: 'root',
})
export class AssetStore extends CoreStore<Asset, AssetState, AssetApiService> {
  protected override serviceType = AssetApiService;

  constructor() {
    super({ entities: [], loading: false });
  }

  readonly initialize = this.effect((params$) => {
    return params$.pipe(
      switchMap(() => {
        return this.service.initialize().pipe(
          catchError((error: Error) => {
            console.error('Caught error:', error);
            this.patchState({ error: error.message, loading: false });
            return of([]);
          }),
        );
      }),
      finalize(() => {
        this.patchState({ loading: false });
      }),
    );
  });
}
