import { Injectable } from '@angular/core';
import { CoreApiService } from './core.service';
import { Asset, PushNotification } from 'src/app/domain';
import { HttpClient } from '@angular/common/http';
import { DataService } from '..';
import { FirebaseApp } from '@angular/fire/app';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { QueryOptions } from '../types';
import { IndexableType } from 'dexie';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root',
})
export class AssetApiService extends CoreApiService<Asset> {
  protected override dbPath: string = 'assets';
  protected getServiceIdentifier(): string {
    return 'AssetApiService';
  }
  constructor(http: HttpClient, firebaseApp: FirebaseApp, dataService: DataService<PushNotification>) {
    super(http, firebaseApp, dataService);
  }

  search(searchTerm: string): Observable<Asset[]> {
    const searchFields = ['name']; // Add more fields as needed
    return this.dataService.searchLocalData(this.dbPath, searchFields, searchTerm);
  }

  override getAll(queryOptions: QueryOptions): Observable<Asset[]> {
    return this.dataService.getAllLocalData<Asset>(this.dbPath);
  }

  initialize(): Observable<IndexableType | undefined> {
    return from(Network.getStatus()).pipe(
      switchMap(({ connected }) => {
        if (connected) {
          const endpoint = `${this.url}/${this.dbPath}`;
          const queryOptions: QueryOptions = { limit: 500 };
          return this.dataService
            .fetchAndStoreAll(this.dbPath, endpoint, queryOptions)
            .pipe(switchMap(() => this.dataService.setCollectionSynced(this.dbPath, true)));
        }
        return of(undefined);
      }),
    );
  }
}
