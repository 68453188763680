import { DestroyRef, inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of, lastValueFrom, BehaviorSubject } from 'rxjs';
import { switchMap, map, catchError, tap, take, filter } from 'rxjs/operators';
import Dexie, { IndexableType } from 'dexie';
import * as CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import { CoreModel } from 'src/app/domain';
import { QueryOptions } from '../types';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { Network } from '@capacitor/network';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SYNC_NOTIFIER } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class DataService<T extends CoreModel> {
  private secretKey = 'CHART-PAPER-SCISSORS-ROCK';
  private db!: Dexie;
  private INSTALLATION_KEY = 'installation_id';
  private isInitialized$ = new BehaviorSubject<boolean>(false);
  private dbName = 'ChartPaper';

  destroyRef = inject(DestroyRef);

  constructor(private http: HttpClient) {
    this.initializeService();
  }

  private async initializeService() {
    try {
      await this.initializeDatabase();
      await this.checkInstallationId();
      this.setupNetworkListener();
      this.isInitialized$.next(true);
    } catch (error) {
      console.error('Failed to initialize service:', error);
    }
  }

  private initializeDatabase() {
    this.db = new Dexie(this.dbName);
    this.db.version(1).stores({
      audits: 'key',
      countries: 'key',
      helps: 'key',
      notifications: 'key',
      people_groups: 'key',
      push_notifications: 'key',
      maps: 'key',
      map_connections: 'key',
      map_permissions: 'key',
      users: 'key',
      templates: 'key',
      organizations: 'key',
      menu_items: 'key',
      app_meta: 'key',
      assets: 'key',
      transactions: '++id, collection, key, data, timestamp, method, endpoint, payload',
      sync_status: 'collection',
    });

    return this.db.open().catch((error) => {
      console.error('Error opening database:', error);
      return this.recoverDatabase();
    });
  }

  private async recoverDatabase(): Promise<void | Dexie> {
    try {
      await Dexie.delete(this.dbName);
      console.log('Deleted existing database');
      return this.initializeDatabase();
    } catch (error) {
      console.error('Failed to recover database:', error);
      throw new Error('Unable to initialize database');
    }
  }

  private async checkInstallationId() {
    const installationId = await this.getInstallationId();
    if (!installationId) {
      await this.setInstallationId();
      await this.wipeAllData();
    }
  }

  private async getInstallationId(): Promise<string | null> {
    try {
      if (Capacitor.isNativePlatform()) {
        const { value } = await Preferences.get({ key: this.INSTALLATION_KEY });
        return value;
      } else {
        const record = await this.db.table('app_meta').get(this.INSTALLATION_KEY);
        return record ? record.value : null;
      }
    } catch (error) {
      console.error('Error getting installation ID:', error);
      return null;
    }
  }

  private async setInstallationId() {
    const installationId = uuidv4();
    try {
      if (Capacitor.isNativePlatform()) {
        await Preferences.set({
          key: this.INSTALLATION_KEY,
          value: installationId,
        });
      } else {
        await this.db.table('app_meta').put({ key: this.INSTALLATION_KEY, value: installationId });
      }
    } catch (error) {
      console.error('Error setting installation ID:', error);
    }
  }

  private setupNetworkListener() {
    Network.addListener('networkStatusChange', (status) => {
      console.log('Network status changed:', status.connected);
      if (status.connected) {
        console.log('Device is online. Starting sync...');
        this.syncTransactions()
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            catchError((error) => {
              console.error('Error syncing transactions:', error);
              return of(undefined);
            }),
          )
          .subscribe(() => {
            console.log('Sync completed');
          });
      } else {
        console.log('Device is offline');
      }
    });
  }

  private ensureInitialized<T>(operation: () => Observable<T>): Observable<T> {
    return this.isInitialized$.pipe(
      filter((isInitialized) => isInitialized),
      take(1),
      switchMap(() => operation()),
    );
  }
  getData<T>(collection: string, key: string, endpoint: string, params?: any): Observable<T | null> {
    return this.ensureInitialized(() =>
      from(this.getLocalData<T>(collection, key)).pipe(
        switchMap((localData) =>
          this.getOnlineStatue().pipe(
            switchMap((online) => {
              if (online) {
                return this.fetchFromApiAndStore<T>(collection, key, endpoint, params).pipe(
                  catchError(() => of(localData)),
                );
              } else {
                return of(localData);
              }
            }),
          ),
        ),
      ),
    );
  }

  fetchFromApiAndStore<T>(collection: string, key: string, endpoint: string, params?: any): Observable<T> {
    return this.http.get<T>(endpoint, { params }).pipe(
      switchMap((data) => this.setLocalData(collection, key, data).pipe(map(() => data))),
      switchMap((data) => this.saveTransaction(collection, key, data, 'GET', endpoint, params).pipe(map(() => data))),
    );
  }

  postData<T extends CoreModel>(collection: string, endpoint: string, data: any): Observable<T> {
    return this.ensureInitialized(() =>
      this.getOnlineStatue().pipe(
        switchMap((online) => {
          if (online) {
            return this.setLocalData(collection, data.id!, data).pipe(map(() => data));
          } else {
            return this.saveTransaction(collection, null, data, 'POST', endpoint);
          }
        }),
      ),
    );
  }

  putData<T>(collection: string, endpoint: string, key: string, data: any): Observable<T> {
    return this.ensureInitialized(() =>
      this.getOnlineStatue().pipe(
        switchMap((online) => {
          if (online) {
            return this.http.put<T>(endpoint, data).pipe(
              switchMap((response) => {
                return this.setLocalData(collection, key, response).pipe(map(() => response));
              }),
            );
          } else {
            return this.saveTransaction(collection, key, data, 'PUT', endpoint).pipe(map(() => data));
          }
        }),
      ),
    );
  }

  deleteData<T>(collection: string, endpoint: string, key: string): Observable<T | null> {
    return this.ensureInitialized(() =>
      this.getOnlineStatue().pipe(
        switchMap((online) => {
          if (online) {
            return from(this.getLocalData<T>(collection, key)).pipe(
              switchMap((data) => {
                if (!data) return of(null);
                return from(this.removeLocalData(collection, key)).pipe(map(() => data));
              }),
            );
          } else {
            return this.saveTransaction<T>(collection, key, null, 'DELETE', endpoint);
          }
        }),
      ),
    );
  }

  setLocalData<T>(collection: string, key: string, data: T): Observable<T> {
    if (key.includes('undefined') || key.includes('where') || key.includes('{}')) return of(data);

    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
    return from(this.db.table(collection).put({ key, value: encryptedData })).pipe(
      map(() => data),
      catchError((error) => {
        return of(data);
      }),
    );
  }

  getLocalData<T>(collection: string, key: string): Promise<T | null> {
    if (!key) return Promise.resolve(null);
    return this.db
      .table(collection)
      .get({ key })
      .then((record) => {
        if (record && record.value) {
          const bytes = CryptoJS.AES.decrypt(record.value, this.secretKey);
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return decryptedData as T;
        }
        return null;
      })
      .catch((error) => {
        console.log('Error getting data from storage', error);
        return null;
      });
  }

  removeLocalData(collection: string, key: string): Promise<void> {
    return this.db
      .table(collection)
      .delete(key)
      .then(() => {
        console.log(`Deleted from ${collection} with key ${key}`);
      })
      .catch((error) => {
        console.log('Error deleting data from storage', error);
      });
  }

  clearData(collection: string, key: string): Observable<void> {
    return this.ensureInitialized(() => from(this.db.table(collection).delete(key)));
  }

  clearAllData(collection: string): Observable<void> {
    return this.ensureInitialized(() => from(this.db.table(collection).clear()));
  }

  wipeAllData(): Promise<void[]> {
    const collections = [
      'audits',
      'helps',
      'notifications',
      'push_notifications',
      'maps',
      'map_connections',
      'map_permissions',
      'users',
      'templates',
      'organizations',
      'menu_items',
      'transactions',
      'assets',
    ];
    return Promise.all(collections.map((collection) => this.db.table(collection).clear()));
  }

  saveTransaction<T>(
    collection: string,
    key: string | null,
    data: T | null,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    endpoint: string,
    payload?: any,
  ): Observable<T | null> {
    return from(this.db.table('transactions').where({ collection, key }).first()).pipe(
      switchMap((existingTransaction) => {
        const transaction = {
          collection,
          key,
          data,
          method,
          endpoint,
          payload,
          timestamp: new Date().toISOString(),
        };

        if (existingTransaction) {
          // Update existing transaction
          return from(this.db.table('transactions').update(existingTransaction.id, transaction));
        } else {
          // Add new transaction
          return from(this.db.table('transactions').add(transaction));
        }
      }),
      switchMap(() => {
        // Update local data immediately
        if ((method === 'POST' || method === 'PUT') && key && data) {
          return this.setLocalData(collection, key, data);
        } else if (method === 'DELETE' && key) {
          return from(this.removeLocalData(collection, key)).pipe(map(() => null));
        }
        return of(data);
      }),
      map((result) => {
        if (method === 'DELETE') {
          return null;
        }
        return result as T;
      }),
    );
  }

  syncTransactions(): Observable<void> {
    return this.ensureInitialized(() =>
      from(this.db.table('transactions').toArray()).pipe(
        switchMap((transactions) => {
          console.log('Starting sync of transactions:', transactions.length);
          const syncPromises = transactions.map(async (transaction) => {
            try {
              console.log('Processing transaction:', JSON.stringify(transaction));
              let response;
              if (transaction.method === 'POST') {
                response = await lastValueFrom(this.http.post(transaction.endpoint, transaction.data));
              } else if (transaction.method === 'PUT') {
                response = await lastValueFrom(this.http.put(transaction.endpoint, transaction.data));
              } else if (transaction.method === 'DELETE') {
                response = await lastValueFrom(this.http.delete(transaction.endpoint));
              }
              console.log('Response from server:', JSON.stringify(response));
              if (response) {
                await this.db.table('transactions').delete(transaction.id!);
                console.log('Transaction processed and deleted:', transaction.id);
                // Update local data to reflect the change
                if (transaction.method !== 'DELETE' && transaction.key) {
                  await lastValueFrom(this.setLocalData(transaction.collection, transaction.key, response));
                  console.log('Local data updated for:', transaction.key);
                } else if (transaction.method === 'DELETE' && transaction.key) {
                  await this.removeLocalData(transaction.collection, transaction.key);
                  console.log('Local data deleted for:', transaction.key);
                }
              } else {
                console.warn('No response received for transaction:', transaction.id);
              }
            } catch (error) {
              console.error('Error processing transaction:', transaction.id, error);
            }
          });
          return from(Promise.all(syncPromises));
        }),
        tap(() => console.log('All transactions processed')),
        tap(() => SYNC_NOTIFIER.next()),
        map(() => undefined),
        catchError((error) => {
          console.error('Error in syncTransactions:', error);
          return of(undefined);
        }),
      ),
    );
  }

  saveMultipleData(dataMap: { [key: string]: any[] }): Observable<void> {
    return this.ensureInitialized(() =>
      from(
        Promise.all(
          Object.entries(dataMap).flatMap(([collection, dataArray]) =>
            dataArray.map((data) => {
              const id = data.id || uuidv4();
              return lastValueFrom(this.setLocalData(collection, id, data));
            }),
          ),
        ),
      ).pipe(map(() => undefined)),
    );
  }

  clearMultipleData(collectionKeys: string[]): Observable<void> {
    return this.ensureInitialized(() =>
      from(Promise.all(collectionKeys.map((collection) => this.db.table(collection).clear()))).pipe(
        map(() => undefined),
      ),
    );
  }

  getMultipleData(collectionKeys: string[]): Observable<{ [key: string]: any[] }> {
    return this.ensureInitialized(() =>
      from(
        Promise.all(
          collectionKeys.map((collection) =>
            this.db
              .table(collection)
              .toArray()
              .then((records) => ({
                collection,
                records: records.map((record) => {
                  const bytes = CryptoJS.AES.decrypt(record.value, this.secretKey);
                  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                }),
              })),
          ),
        ),
      ).pipe(
        map((results) => {
          const dataMap: { [key: string]: any[] } = {};
          results.forEach((result) => {
            dataMap[result.collection] = result.records;
          });
          return dataMap;
        }),
      ),
    );
  }

  isCollectionSynced(collection: string): Observable<boolean> {
    return this.ensureInitialized(() =>
      from(this.db.table('sync_status').get({ collection })).pipe(map((syncStatus) => syncStatus?.isSynced || false)),
    );
  }

  setCollectionSynced(collection: string, isSynced: boolean): Observable<IndexableType> {
    return this.ensureInitialized(() => from(this.db.table('sync_status').put({ collection, isSynced })));
  }

  getCollectionSyncedStatus(collection: string): Observable<boolean> {
    return this.isCollectionSynced(collection);
  }

  fetchAndStoreInChunks(
    collection: string,
    endpoint: string,
    queryOptions: QueryOptions,
    chunkSize: number,
  ): Observable<void> {
    return this.ensureInitialized(
      () =>
        new Observable<void>((observer) => {
          let hasMoreData = true;
          let lastDoc: any = null;

          const fetchNextChunk = async () => {
            if (!hasMoreData) {
              observer.next();
              observer.complete();
              return;
            }

            const modifiedQueryOptions = { ...queryOptions, limit: chunkSize };
            if (lastDoc) {
              modifiedQueryOptions.startAfter = lastDoc;
            }

            try {
              const dataChunk = await lastValueFrom(
                this.http.get<any[]>(endpoint, { params: modifiedQueryOptions as any }),
              );

              if (dataChunk.length > 0) {
                lastDoc = dataChunk[dataChunk.length - 1][queryOptions.orderBy || 'id'];

                for (const data of dataChunk) {
                  const key = data.id || uuidv4();
                  await lastValueFrom(this.setLocalData(collection, key, data));
                }

                fetchNextChunk();
              } else {
                hasMoreData = false;
                fetchNextChunk();
              }
            } catch (error) {
              console.error('Error fetching data chunk', error);
              observer.error(error);
            }
          };

          fetchNextChunk();
        }),
    );
  }

  fetchAndStoreAll(collection: string, endpoint: string, queryOptions: QueryOptions): Observable<void> {
    return this.ensureInitialized(() =>
      from(this.http.get<any[]>(endpoint, { params: queryOptions as any })).pipe(
        switchMap((data) => {
          return from(
            Promise.all(
              data.map((record) => {
                const key = record.id || uuidv4();
                return lastValueFrom(this.setLocalData(collection, key, record));
              }),
            ),
          );
        }),
        map(() => undefined),
      ),
    );
  }

  searchLocalData<T>(collection: string, searchFields: string[], searchTerm: string): Observable<T[]> {
    return this.ensureInitialized(() =>
      from(
        this.db
          .table(collection)
          .filter((record) => {
            const decryptedValue = CryptoJS.AES.decrypt(record.value, this.secretKey).toString(CryptoJS.enc.Utf8);
            const data = JSON.parse(decryptedValue);
            return searchFields.some((field) => data[field]?.toLowerCase().includes(searchTerm.toLowerCase()));
          })
          .toArray(),
      ).pipe(
        map((records) => {
          return records.map((record) => {
            const bytes = CryptoJS.AES.decrypt(record.value, this.secretKey);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) as T;
          });
        }),
      ),
    );
  }

  getAllLocalData<T>(collection: string): Observable<T[]> {
    return this.ensureInitialized(() =>
      from(this.db.table(collection).toArray()).pipe(
        map((records) => {
          return records.map((record) => {
            const bytes = CryptoJS.AES.decrypt(record.value, this.secretKey);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) as T;
          });
        }),
      ),
    );
  }

  getOnlineStatue(): Observable<boolean> {
    return from(Network.getStatus()).pipe(map((status) => status.connected));
  }
}
