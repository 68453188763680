import { signal } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

export const imagesCollection = [
  '/assets/icons/svg/stat/dunk.svg',
  '/assets/icons/svg/stat/dunk2.svg',
  '/assets/icons/svg/stat/supper.svg',
  '/assets/icons/svg/stat/diag.svg',
  '/assets/icons/svg/stat/group 6.svg',
  '/assets/icons/svg/stat/love.svg',
  '/assets/icons/svg/stat/cross.svg',
  '/assets/icons/svg/stat/worship.svg',
  '/assets/icons/svg/stat/shepherd.svg',
  '/assets/icons/svg/stat/currency-rupee.svg',
  '/assets/icons/svg/stat/users-between-lines.svg',
  '/assets/icons/svg/stat/users.svg',
  '/assets/icons/svg/stat/calendar.svg',
  '/assets/icons/svg/stat/location-marker.svg',
  'assets/icons/svg/functions/partial.svg',
  'assets/icons/svg/functions/badge.svg',
  '/assets/icons/svg/stat/heart.svg',
  '/assets/icons/svg/stat/bolt.svg',
  '/assets/icons/svg/stat/dollar.svg',
  '/assets/icons/svg/stat/light.svg',
  '/assets/icons/svg/stat/attenders.png',
  '/assets/icons/svg/stat/believers.png',
  '/assets/icons/svg/stat/element-baptism.png',
  '/assets/icons/svg/stat/element-give.png',
  '/assets/icons/svg/stat/element-leaders.png',
  '/assets/icons/svg/stat/element-lords-supper.png',
  '/assets/icons/svg/stat/element-love.png',
  '/assets/icons/svg/stat/element-prayer.png',
  '/assets/icons/svg/stat/element-word.png',
  '/assets/icons/svg/stat/element-worship.png',
  '/assets/icons/svg/stat/element-make-disciples.png',
];

const base64Promises = imagesCollection.map((url) => transformToBase64(url));

export const base64Images = signal<SafeUrl[]>([]);
export async function getBase64Images(): Promise<void> {
  const _base64Images = await Promise.all(base64Promises);
  base64Images.set(_base64Images);
}

async function transformToBase64(url: string): Promise<SafeUrl> {
  const base64String = await convertToBase64(url);
  return base64String;
}

function convertToBase64(url: string): Promise<string> {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );
}
