import { lastValueFrom } from 'rxjs';
import { AssetApiService } from 'src/app/services';

export async function imageParser(assetService: AssetApiService, name: string): Promise<string> {
  const assets = await lastValueFrom(assetService.search(name));
  if (assets.length) {
    return assets[0].url;
  } else {
    return 'assets/icons/svg/dummy.svg';
  }
}
