import { ChangeDetectorRef, Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { MapConnectionStore, MapPermissionStore, MapStore, OrganizationStore } from 'src/app/store';
import { ModalController, PopoverController, ToastController, ActionSheetController } from '@ionic/angular/standalone';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MapService } from 'src/app/services';
import { MapRoleType } from '../types';
import { combineLatest, filter, Subscription } from 'rxjs';
import { Map, UnifiedUserInfo } from 'src/app/domain';

@Component({
  selector: 'app-common',
  template: ``,
})
export abstract class CommonComponent implements OnInit, OnDestroy {
  abstract componentName: string;
  organizationStore = inject(OrganizationStore);
  mapStore = inject(MapStore);
  mapPermissionStore = inject(MapPermissionStore);
  mapConnectionStore = inject(MapConnectionStore);
  service = inject(MapService);
  router = inject(Router);
  modalCtrl = inject(ModalController);
  popoverCtrl = inject(PopoverController);
  toastCtrl = inject(ToastController);
  actionSheetCtrl = inject(ActionSheetController);
  activeRoute = inject(ActivatedRoute);
  destroyRef = inject(DestroyRef);
  cdr = inject(ChangeDetectorRef);

  defaultOrganization = this.organizationStore.selectedEntity;
  maps = this.mapStore.entities;
  mapPermissions = this.mapPermissionStore.entities;
  mapConnections = this.mapConnectionStore.entities;
  loading = toSignal(this.service.isLoading$, { initialValue: false });
  isPrivate = toSignal(this.service.isPrivate$, { initialValue: false });

  mapId = '';
  title = '';
  nodeId = '';
  childOnly = false;
  role: MapRoleType = 'view';
  userId = this.service.userId;

  protected routerSubscription!: Subscription;

  ngOnInit(): void {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (!this.router.url.includes('map')) {
          this.service.reloadEntities(() => {}, false);
        }
      });

    this.activeRoute.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (params) => {
      this.mapId = params['mapId'];
      this.nodeId = params['nodeId'];
      this.title = params['title'];
      this.childOnly = params['childOnly'];
    });

    combineLatest([this.service.currentMap$, this.mapPermissionStore.entities$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async ([map, permissions]) => {
        if (!this.mapId) return;

        if (map && permissions.length > 0) {
          const mappedPermissions = permissions.map((permission) => {
            return {
              ...permission,
              mapId: this.getMapId(permission.mapId),
              userId: this.getUserId(permission.userId!),
            };
          });
          const filteredPermissions = mappedPermissions.filter(
            (permission) => permission.mapId === this.mapId && permission.userId === this.userId,
          );
          const role = filteredPermissions.length > 0 ? filteredPermissions[0].role : 'view';
          this.role = role;

          this.cdr.markForCheck();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private getMapId(map: Map | string): string {
    return typeof map === 'string' ? map : map?.id ?? '';
  }
  private getUserId(userId: string | UnifiedUserInfo): string {
    return typeof userId === 'string' ? userId : userId.uid ?? '';
  }
}
