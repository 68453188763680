import { inject, Injectable } from '@angular/core';
import { CountryApiService, PeopleGroupApiService } from '../apis';
import { BehaviorSubject, forkJoin, from, of, Subject, switchMap, catchError, tap, lastValueFrom } from 'rxjs';
import { DataService } from '..';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInitializerService {
  countriesService = inject(CountryApiService);
  peopleGroupsService = inject(PeopleGroupApiService);
  dataService = inject(DataService);

  mapUpdateNotifier = new Subject<string>();
  mapDeleteNotifier = new Subject<string>();
  updateTemplateSubject = new BehaviorSubject<boolean>(false);
  appStateChangedNotifier = new Subject<void>();

  countrySynced = new BehaviorSubject<boolean>(false);
  peopleGroupsSynced = new BehaviorSubject<boolean>(false);
  everythingSynced = new BehaviorSubject<boolean>(false);

  async initialize(): Promise<void> {
    const { connected } = await Network.getStatus();
    if (connected) {
      try {
        await lastValueFrom(
          forkJoin([from(this.countriesService.initialize()), from(this.peopleGroupsService.initialize())]),
        );

        await lastValueFrom(
          forkJoin([
            this.countriesService.getSyncStatus(),
            this.peopleGroupsService.getSyncStatus(),
            this.dataService.isCollectionSynced('reload-app'),
          ]).pipe(
            switchMap(([countriesSynced, peopleGroupsSynced, reloadApp]) => {
              this.countrySynced.next(countriesSynced);
              this.peopleGroupsSynced.next(peopleGroupsSynced);
              if (countriesSynced && peopleGroupsSynced) {
                if (!reloadApp) {
                  return this.dataService.setCollectionSynced('reload-app', true).pipe(
                    tap(() => {
                      this.everythingSynced.next(true);
                    }),
                  );
                } else {
                  this.everythingSynced.next(true);
                  return of(null);
                }
              } else {
                return of(null);
              }
            }),
            catchError((error) => {
              console.error('Error during initialization:', error);
              return of(null);
            }),
            tap(() => {}),
          ),
        );
      } catch (error) {
        console.error('Error during initialization:', error);
      }
    } else {
      console.error('Network is not connected');
    }
  }
}
